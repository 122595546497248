import React from 'react';
import Spinner from 'react-spinkit';
import { defineMessages } from 'react-intl';
import { KyruusFormattedMessage } from '@kyruus/intl';
import { bool } from 'prop-types';

const messages = defineMessages({
  ariaLoading: {
    id: 'aria.label.loading',
    description: 'Accessibility label for the loading spinner',
    defaultMessage: 'Loading {message}'
  }
});

export default function LoadingOverlay({ loading }) {
  return (
    <>
      <span className="sr-only" aria-live="polite">
        <KyruusFormattedMessage
          {...messages.ariaLoading}
          values={{ message: loading === false ? 'Complete' : null }}
        />
      </span>
      {loading === true && (
        <div className="loading-overlay">
          <div className="spinner-container">
            <Spinner name="wandering-cubes" fadeIn={'none'} />
          </div>
        </div>
      )}
    </>
  );
}

LoadingOverlay.propTypes = {
  loading: bool.isRequired
};
