import axios from 'axios';
import { Base64 } from 'js-base64';
import UAParser from 'ua-parser-js';

const DEFAULT_DATA = {
  customer_code: '',
  deployment: '', // stg, prd, dev, etc
  event_data: {},
  event_name: '',
  event_time: '',
  level: 'INFO',
  message: '',
  host: '',
  name: 'providermatch_consumer',
  product_name: 'Providermatch Server',
  product_version: '',
  referrer: 'UNKNOWN',
  tracking_token: 'UNKNOWN',
  user_id: 'UNKNOWN',
  user_metadata: {},
  utm_param: 'UNKNOWN'
};

/**
 * Make a tracking request to the Kyruus "Kloggyr" service which creates log entries
 * that are later ingested and viewable via Kyruus "MUUS"
 * @param {Request} req the http request for context
 * @param {Object} details:
 *    level - error level ie INFO or WARNING or ERROR
 *    message - message to log to kloggyr. required
 *    eventName - optional event name to label in kloggyr. If not set, one will automatically be created for this event
 *    error - optional Error object that will be used to send additional data to kloggyr if available
 */
export async function sendToKloggyr(
  req,
  {
    level = 'INFO',
    message,
    eventName = '',
    error = null,
    additionalEventData = {}
  }
) {
  try {
    const appSettings = await req.getAppSettings();
    const trackingObject = await buildTrackingObject(req, {
      level,
      message,
      eventName,
      error,
      additionalEventData
    });

    if (trackingObject && appSettings.KLOGGYR_URL) {
      const dataString = JSON.stringify(trackingObject);
      const requestUrl = `${
        appSettings.KLOGGYR_URL
      }api/log?data=${Base64.encodeURI(dataString)}`;
      // send tracking data. The response is binary image tracking pixel as this endpoint is primarily
      // used for client-side tracking, and we have no use for the response here
      await axios.get(requestUrl, {
        timeout: appSettings.TIMEOUT_KLOGGYR
      });
      return true;
    } else {
      return false;
    }
  } catch (error) {
    // failed to send to kloggyr
  }
}

export async function buildTrackingObject(
  req,
  {
    level = 'INFO',
    message,
    eventName = '',
    error = null,
    additionalEventData = {}
  }
) {
  const appSettings = await req.getAppSettings();
  const appVersion = await req.getAppVersion();
  const actor = await req.getActor();

  let customerCode = 'none';

  if (actor) {
    // if actor were null, getCustomerCode will always fail
    try {
      customerCode = await req.getCustomerCode();
    } catch (error) {
      // customerCode remains 'none'
    }
  }

  if (!eventName && error && error.name) {
    eventName = error.name;
  }
  if (!eventName) {
    // eventName is a required field
    eventName = 'missing event name';
  }

  let browser = 'unknown';
  let browser_version = 'unknown';
  let platform = 'unknown';

  if (req.headers['user-agent']) {
    try {
      const parser = new UAParser(req.headers['user-agent']);
      browser = parser.getBrowser().name || 'unknown';
      browser_version = parser.getBrowser().version || 'unknown';
      platform = parser.getOS().name || 'unknown';
    } catch (e) {
      // could not parse user agent :shrug:
    }
  }

  const fullData = {
    ...DEFAULT_DATA,
    customer_code: customerCode,
    deployment: appSettings.KLOGGYR_DEPLOYMENT,
    event_name: eventName,
    event_data: {
      ...DEFAULT_DATA.event_data,
      customer_id: customerCode,
      actor,
      page: req.path,
      search_token: await req.getSearchToken(),
      user_token: await req.getUserToken(),
      user_type: 'Anonymous',
      url: req.url,
      method: req.method,
      remote_addr: req.headers['x-forwarded-for'] || 0,
      referrer: req.headers['referrer'] || 'none',
      user_agent: req.headers['user-agent'] || 'unknown',
      browser,
      browser_version,
      platform,
      ...additionalEventData
    },
    message,
    level,
    event_time: new Date().toISOString(),
    product_version: appVersion,
    kloggyr_version: `pmc ${appVersion}`,
    referrer: req.headers.referer || 'none',
    tracking_token: await req.getConsumerTrackingToken(),
    user_id: await req.getUserToken()
  };
  if (error) {
    if (error.lineNumber) {
      fullData.line_number = error.lineNumber;
    }
    if (error.fileName) {
      fullData.file_name = error.fileName;
    }
    if (error.stack) {
      fullData.stack = error.stack;
    }
  }
  return fullData;
}
