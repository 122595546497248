import _startCase from 'lodash/startCase';
import { defineMessages } from 'react-intl';

import {
  ENTITY_TYPE,
  LOCATION_ENTITY_TYPE,
  V8_PATIENT_REL_FILTER_KEY,
  V8_PURPOSE_FILTER_KEY,
  V8_VISIBILITIES_FILTER_BASE,
  V8_DIRECT_BOOK_CAPABLE_FILTER,
  ESTABLISHED_PATIENT,
  NEW_PATIENT,
  V9_HAS_AVAILABILITY_PARAM_KEY,
  V9_PATIENT_REL_FILTER_KEY,
  V9_PURPOSE_FILTER_KEY,
  SCHEDULING_VISIBILITY_CONSUMER
} from '../utils/constants';
import { splitOnce } from 'Common/utils/splitOnce';

const messages = defineMessages({
  clinical_keywords: {
    id: 'search.searchtypes.clinical_keywords',
    description: 'Category name for a suggested `clinical_keywords` search',
    defaultMessage: 'Conditions'
  },
  location_name: {
    id: 'search.searchtypes.location_name',
    description: 'Category name for a suggested `location_name` search',
    defaultMessage: 'Locations'
  },
  network_affiliation: {
    id: 'search.searchtypes.network_affiliation',
    description: 'Category name for a suggested `network_affiliation` search',
    defaultMessage: 'Affiliations'
  },
  provider_name: {
    id: 'search.searchtypes.provider_name',
    description: 'Category name for a suggested `provider_name` search',
    defaultMessage: 'Providers'
  },
  specialties: {
    id: 'search.searchtypes.specialties',
    description: 'Category name for a suggested `specialties` search',
    defaultMessage: 'Specialties'
  }
});

// filter out any availability_density_best larger than this number.
// Search team uses extraordinarily large numbers for sort purposes but
// we don't actually want to show those large numbers in the UI
const MAX_ADB = 60;

function searchCategoryDisplayDescriptor(category) {
  return (
    messages[category] || {
      id: `search.searchtypes.${category}`,
      defaultMessage: _startCase(category)
    }
  );
}

/**
 * Given a provider, will return a list of purpose options by patientRel
 * @param {Object} provider
 * @returns {Object} {new: Set(), established: Set()}
 */
function getPurposeOptionsByPatientRel(
  provider,
  visibility = SCHEDULING_VISIBILITY_CONSUMER
) {
  const purposeByRel = {
    [NEW_PATIENT]: [],
    [ESTABLISHED_PATIENT]: []
  };
  const [visKey, visValue] = splitOnce(visibility, ':');
  const ehrPurposes = provider.appointment_ehr_purposes || [];

  ehrPurposes.forEach(({ ehr_data, name, patient_relationship }) => {
    const isVisible = ehr_data.find(({ visibilities }) => {
      return visibilities[visKey] === visValue;
    });

    if (
      isVisible &&
      [ESTABLISHED_PATIENT, NEW_PATIENT].includes(patient_relationship)
    ) {
      if (
        !purposeByRel[patient_relationship].find(
          (purpose) => purpose.name === name
        )
      ) {
        purposeByRel[patient_relationship].push({
          name,
          visit_method: isVisible.visit_method
        });
      }
    }
  });

  const sortByName = (purposes) =>
    purposes.sort((a, b) => a.name.localeCompare(b.name));

  const results = {
    new: sortByName(purposeByRel.new),
    established: sortByName(purposeByRel.established)
  };

  return results;
}

/**
 * Takes in an array of `${key}:${value}` strings and converts them into an { [key]: `${value}` } object
 * @param {arr} array of `${key}:${value}` strings
 * @returns {object} an object with { [key]: `${value}` } entries
 */
function splitObjectKeys(arr) {
  return arr.map((item) => {
    const [key, value] = splitOnce(item, ':');
    return {
      [key]: value
    };
  });
}

/**
 * Determines whether or not we should hide the patient relationship question on the ApptInfo
 * step in the flow based on the provider.entity_type and a config flag (hide_patient_rel_for_locations)
 * @param {object} provider a provider or bookable entity (e.g. location/clinic)
 * @param {boolean} hidePatientRelForLocations config flag; true means hide the patient rel question
 * @param returns true if we should hide the patient rel question, else false
 */
function hidePatientRel(provider, hidePatientRelForLocations) {
  if (
    provider[ENTITY_TYPE] &&
    provider[ENTITY_TYPE] === LOCATION_ENTITY_TYPE &&
    hidePatientRelForLocations === true
  ) {
    return true;
  } else {
    return false;
  }
}

/**
 * Generates a list of modification objects (consumable by getUpdatedSearch)
 * to be used to delete all current query params related to availability tiles.
 * @param {object} query an object representing the search query to be modified
 * @returns {array} list of modification objects to pass to getUpdatedSearch
 */
function getQueryModsToDeleteAvailParams(query) {
  const isAvailabilityFilter = (val) =>
    val &&
    (val.includes(V8_PATIENT_REL_FILTER_KEY) ||
      val.includes(V8_PURPOSE_FILTER_KEY) ||
      val.includes(V8_VISIBILITIES_FILTER_BASE) ||
      val.includes(V8_DIRECT_BOOK_CAPABLE_FILTER));

  let modValues = [];

  if (Array.isArray(query.filter)) {
    modValues = query.filter.filter((f) => {
      return isAvailabilityFilter(f);
    });
  } else {
    if (isAvailabilityFilter(query.filter)) {
      modValues.push(query.filter);
    }
  }

  const availMods = modValues.map((filter) => {
    return { action: 'delete_key_value', key: 'filter', value: filter };
  });

  return availMods;
}

/**
 * Function to determine if the disclaimer message should be shown in the SearchFooter. The disclaimer's '*' correspondes to the '*' shown in 'Available within X days*' message in a provider-tile
 * @param {array} providers
 * @returns {boolean} Returns true if at least one provider has a truthy availabilty_density_best
 */
function showSearchFooterDisclaimer(providers = []) {
  return providers.some((provider) =>
    (provider.sort || []).find(
      (sortItem) =>
        sortItem.availability_density_best &&
        sortItem.availability_density_best < MAX_ADB
    )
  );
}

/**
 * Given search params (object of key/values for search api) and an nlpResult from v9 search API, return a searchParam
 * that represents the original searchParams search with any params from the NLP result included
 * @param {Object} searchParams
 * @param {Object} nlpActions nlp actions from v9 search result
 */
function mergeSearchParamsAndNlpResult(searchParams, nlpActions) {
  try {
    let updatedSearchParams = { ...searchParams };
    if (nlpActions) {
      if (nlpActions.filter?.length) {
        const nlpFilters = nlpActions.filter;
        // combine NLP and searchParams
        updatedSearchParams = {
          ...updatedSearchParams,
          filter: [
            // merge searchParams.filter with nlpFilters, preserving only unique non-empty items
            ...new Set(
              // searchParams.filter is either an array or single string, so account for either case here
              [updatedSearchParams.filter, ...nlpFilters].filter(Boolean).flat()
            )
          ]
        };
        updatedSearchParams.unified = nlpActions.nlp_search;
      }
      if (nlpActions.location) {
        updatedSearchParams = {
          ...updatedSearchParams,
          display_location: nlpActions.location,
          location: nlpActions.location
        };
        updatedSearchParams.unified = nlpActions.nlp_search;
      }
      if (nlpActions.sort) {
        updatedSearchParams = {
          ...updatedSearchParams,
          sort: nlpActions.sort
        };
      }
    }
    return updatedSearchParams;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    return searchParams;
  }
}

/**
 * Returns the search params to use when re-fetching providers after setting global availability tile options
 * @param {object} config
 * @param {string} patientRel
 * @param {object} purpose
 * @returns {array} objects of key/values for search api
 */
function getParamsForConfirmAvailability({ config, patientRel, purpose }) {
  const { v9DirectBookEnabledFilter } = config.visibility;
  const isAvailabilityReadOnly = config.enable_read_only_availability;
  const filter = [
    `${V9_PATIENT_REL_FILTER_KEY}:${patientRel}`,
    `${V9_PURPOSE_FILTER_KEY}:${purpose.name}`
  ];
  if (!isAvailabilityReadOnly) {
    filter.push(v9DirectBookEnabledFilter);
  }
  return [
    {
      action: 'append',
      key: 'filter',
      value: filter
    },
    {
      action: 'append',
      key: V9_HAS_AVAILABILITY_PARAM_KEY,
      value: true
    }
  ];
}

export {
  searchCategoryDisplayDescriptor,
  getPurposeOptionsByPatientRel,
  splitObjectKeys,
  hidePatientRel,
  getQueryModsToDeleteAvailParams,
  showSearchFooterDisclaimer,
  mergeSearchParamsAndNlpResult,
  getParamsForConfirmAvailability
};
