export const SET_BCS_TOKEN = 'SET_BCS_TOKEN';

export function setBcsToken(token) {
  return {
    type: SET_BCS_TOKEN,
    payload: token
  };
}

export const SET_SEARCH_SHUFFLE_TOKEN = 'SET_SEARCH_SHUFFLE_TOKEN';

export function setSearchShuffleToken(token) {
  return {
    type: SET_SEARCH_SHUFFLE_TOKEN,
    payload: token
  };
}
