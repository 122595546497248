import { SET_BCS_TOKEN } from './actions';
import { SET_SEARCH_SHUFFLE_TOKEN } from './actions';

export default function tokensReducer(state = {}, action) {
  if (action.type === SET_BCS_TOKEN) {
    return {
      ...state,
      bcsToken: action.payload
    };
  }
  if (action.type === SET_SEARCH_SHUFFLE_TOKEN) {
    return {
      ...state,
      searchShuffleToken: action.payload
    };
  }
  return state;
}
