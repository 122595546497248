import _set from 'lodash/set';
import _omit from 'lodash/omit';
import _difference from 'lodash/difference';
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';
import _cloneDeep from 'lodash/cloneDeep';
import _unset from 'lodash/unset';
import querystring from 'querystring';
import axios from 'axios';
import { Search } from '@kyruus/search-sdk';

import { mergeSearchParamsAndNlpResult } from '../../../search-v9/utils';
import { fetchSlots } from '../../availability/actions';

import { getStickyParamsFromLocation } from '../../../utils/url';

import { getSortOptions, getSearchSummary } from '../../../utils/search-common';

import { splitOnce } from 'Common/utils/splitOnce';
import { visibilitySelector, configSelector } from '../../configuration';
import { settingsSelector } from '../../settings';
import FacetConfigurator from '../../../shared/facet-configurator';
import { splitObjectKeys } from './utils';

import {
  constructV9QueryParams,
  constructParamsForSearchSdk,
  constructResponseContext,
  transformFilterUrlParamsForSearchSdk,
  getSuggestionsForDisplay
} from './utils';

import {
  V9_DIRECT_BOOK_CAPABLE_FILTER,
  PROVIDER_ID_FIELD_NAME,
  FACET_DISPLAY_BLACKLIST
} from '../../../utils/constants';
import { sendToKloggyr } from 'Common/logging/kloggyr-service';
import { productNameSelector } from '../../product-name';

export const REQUEST_SEARCH = 'REQUEST_SEARCH';
export const RECEIVE_SEARCH = 'RECEIVE_SEARCH';
export const RECEIVE_SEARCH_V9 = 'RECEIVE_SEARCH_V9';
export const RECEIVE_SEARCH_ERROR = 'RECEIVE_SEARCH_ERROR';

export const UPDATE_PROVIDER_SLOT_COUNTS = 'UPDATE_PROVIDER_SLOT_COUNTS';
export const LOADING_SLOT_COUNTS_START = 'LOADING_SLOT_COUNTS_START';
export const LOADING_SLOT_COUNTS_END = 'LOADING_SLOT_COUNTS_END';
export const ERROR_SLOT_COUNTS = 'ERROR_SLOT_COUNTS';

export const TOGGLE_MOBILE_FACETS = 'TOGGLE_MOBILE_FACETS';

export const UPDATE_PARAMS = 'UPDATE_PARAMS';

export function requestProviders() {
  return {
    type: REQUEST_SEARCH
  };
}

function updateProvidersAfterAppendingSlotCounts(providers) {
  return {
    type: UPDATE_PROVIDER_SLOT_COUNTS,
    payload: {
      providers
    }
  };
}

function receiveProvidersError() {
  return {
    type: RECEIVE_SEARCH_ERROR
  };
}

function loadingSlotCountsStart() {
  return {
    type: LOADING_SLOT_COUNTS_START
  };
}

function loadingSlotCountsEnd() {
  return {
    type: LOADING_SLOT_COUNTS_END
  };
}

function errorSlotCounts() {
  return {
    type: ERROR_SLOT_COUNTS
  };
}

/**
 * Check whether or not a given composite is a filter
 *
 * @param {string} composite the composite to check
 * @returns {boolean}
 */
function isCompositeFilter(composite) {
  return composite.split(':')[0] === 'filter';
}

/**
 * Given a filter value or array of values, return all composite params for those values
 *
 * @param {string | string[]} value - filter value(s) (format: `${filterKey}:${filterValue}` | Array<`${filterKey}:${filterValue}`>)
 * @param {object} customerConfig - a PMC customer config object
 * @returns {object[]} composites - array of composite configuration objects extracted from the customer config
 */
function getComposites(value, customerConfig) {
  const valueArray = Array.isArray(value) ? value : [value];
  const facetsConfig = customerConfig?.facets_v9 || [];
  const composites = valueArray.filter(Boolean).reduce((acc, valueString) => {
    const [filterKey, filterValue] = splitOnce(valueString, ':');
    const facetConfig = facetsConfig.find(
      (facetConfig) => facetConfig.field === filterKey
    );
    const filterHasComposite =
      !!facetConfig?.composite && !!facetConfig.composite[filterValue];

    if (filterHasComposite) {
      const compositesArray = Array.isArray(facetConfig.composite[filterValue])
        ? facetConfig.composite[filterValue]
        : [facetConfig.composite[filterValue]];

      return [...acc, ...compositesArray];
    }

    return acc;
  }, []);
  const [filterComposites, nonfilterComposites] = composites.reduce(
    (acc, composite) => {
      if (isCompositeFilter(composite)) {
        return [acc[0].concat([composite]), acc[1]];
      }

      return [acc[0], acc[1].concat([composite])];
    },
    [[], []]
  );

  return { filterComposites, nonfilterComposites };
}

/**
 * Updates a query object according to a passed in filter modification object
 * Updates query.filter, handles composite params (for a given facet, the customer config may specify additional params to be appended/deleted when the facet is toggled)
 *
 * @param {object} _query - current query object
 * @param {object} mod - modification to make to the query filters (format: { action: 'append' | 'delete_key_value' | 'delete_key', value: string | string[] })
 * @param {object} customerConfig - the PMC customer config object
 * @returns {object} query - (a deeply cloned query object updated according to the passed in `mod`)
 */
export function updateFilterModification(_query, mod, customerConfig) {
  const query = _cloneDeep(_query);
  const currentFilterValuesArray = Array.isArray(query.filter)
    ? query.filter
    : [query.filter];
  const modificationValuesArray = Array.isArray(mod.value)
    ? mod.value
    : [mod.value];
  // get all composite values for this modification
  // `filterComposits` returns with a `filter:` prefix before each `${key}:${value}` pair
  const { filterComposites: unsanitizedFilterComposites, nonfilterComposites } =
    getComposites(modificationValuesArray, customerConfig);
  // remove the `filter:` prefix
  const filterComposites = unsanitizedFilterComposites.map((composite) => {
    const parts = splitOnce(composite, ':');

    parts.shift();

    return parts.join(':');
  });

  switch (mod.action) {
    case 'append': {
      let updatedValues = [
        ...new Set(
          [
            ...currentFilterValuesArray,
            ...modificationValuesArray,
            // add composite values
            ...filterComposites
          ].filter(Boolean)
        )
      ];

      if (Array.isArray(mod.value)) {
        updatedValues = Object.assign(
          {},
          ...splitObjectKeys(updatedValues),
          ...splitObjectKeys(mod.value)
        );
        updatedValues = Object.keys(updatedValues).map(
          (item) => `${item}:${updatedValues[item]}`
        );
      }

      if (nonfilterComposites.length) {
        // set nonfilter composite values onto query
        nonfilterComposites.forEach((v) => {
          const [key, value] = splitOnce(v, ':');
          _set(query, key, value);
        });
      }

      return _set(query, 'filter', updatedValues);
    }

    case 'delete_key_value': {
      // combine with composite values
      const valuesToDelete = [...modificationValuesArray, ...filterComposites];
      const updatedFilterValues = _difference(
        currentFilterValuesArray,
        valuesToDelete
      );

      if (nonfilterComposites.length) {
        // unset nonfilter composites from query
        nonfilterComposites.forEach((v) => {
          const [key, value] = splitOnce(v, ':');
          _unset(query, key, value);
        });
      }

      return _isEmpty(updatedFilterValues)
        ? _omit(query, 'filter')
        : _set(query, 'filter', updatedFilterValues);
    }

    case 'delete_key': {
      // delete all filters as well as any composite values that each filter may have
      const { nonfilterComposites: nonfilterCompositesToDelete } =
        getComposites(currentFilterValuesArray, customerConfig);

      if (nonfilterCompositesToDelete.length) {
        // unset nonfilter composites from query
        nonfilterCompositesToDelete.forEach((v) => {
          const [key, value] = splitOnce(v, ':');
          _unset(query, key, value);
        });
      }

      return _omit(query, 'filter');
    }

    default: {
      return query;
    }
  }
}

function getCustomDistanceSort(config) {
  return _get(config, 'location_facet.custom_sort_order', 'distance');
}

function shouldResetSort(config, query) {
  return !query.location && query.sort == getCustomDistanceSort(config);
}

function createSlotCountQueryUrl(
  providerIds,
  relationshipParam,
  purposeParam,
  customerCode,
  visibility
) {
  const params = {
    _filter: `provider_id:${providerIds.join('#')}`,
    filter: [`visibilities.${visibility}`],
    facet: 'provider_id#location_id'
  };

  if (relationshipParam && purposeParam) {
    params.filter.push(
      `patient_relationship:${splitOnce(relationshipParam, ':')[1]}`
    );
    params.filter.push(`purpose:${splitOnce(purposeParam, ':')[1]}`);
  }

  return `${customerCode}/slots?${querystring.stringify(params)}`;
}

function providerSlotCountZip(providers, slotData) {
  for (const providerSlots of slotData) {
    const providerId = providerSlots.value;
    if (providerSlots.terms && providerSlots.terms.length > 0) {
      const provider = providers.find((provider) => {
        return provider.id == providerId;
      });
      for (const locationSlots of providerSlots.terms) {
        const locationId = locationSlots.value;
        const location = provider.locations.find((value) => {
          return value.id == locationId;
        });
        if (location) {
          location.slots = true;
        }
      }
    }
  }
}

function getRelationshipAndPurposeParams(nextLocation) {
  const { search = '' } = nextLocation;

  const queryParams = querystring.parse(search.slice(1));

  // Get appointment purpose if existing
  const filterIsArray = queryParams.filter && Array.isArray(queryParams.filter);

  const relationshipParam =
    filterIsArray &&
    queryParams.filter.find((value) => {
      return /^provider\.appointment_ehr_purposes\.patient_relationship/.test(
        value
      );
    });

  const purposeParam =
    filterIsArray &&
    queryParams.filter.find((value) => {
      return /^provider\.appointment_ehr_purposes\.name/.test(value);
    });

  return {
    relationshipParam,
    purposeParam
  };
}

/**
 * Grabs searchContext from searchData, if found.
 *
 * @param {object} searchData search service response
 * @returns {Array} `searchContext (the provider.id facet)`
 */
function getSearchContext(searchData) {
  const searchContext = searchData.facets.find(
    ({ field }) => field === PROVIDER_ID_FIELD_NAME
  );
  return searchContext;
}

// module exports:

/**
 * Makes a request to fetch searchContext.
 *
 * @param {object} queryParams current querystring serialized as an object
 * @param {object} searchClient an instance of the search-sdk
 * @param {object} [req] an express request object, if in ssr mode
 *
 * @returns {searchContext} the facet object for the `provider.id` field
 */
export async function fetchSearchContextV9(
  queryParams,
  searchClient,
  req,
  state
) {
  const { enable_read_only_availability: isAvailabilityReadOnly } =
    configSelector(state);
  const params = _cloneDeep(queryParams);

  // don't need to worry about other facets for this request
  params.facet = [[PROVIDER_ID_FIELD_NAME]];

  // transform `params.filter` into an array
  if (!Array.isArray(params.filter) && params.filter) {
    params.filter = [params.filter];
  } else if (!Array.isArray(params.filter) && !params.filter) {
    params.filter = [];
  }

  // always specify this filter when fetching `searchContext`
  // bc we only want to grab purposes for providers who are bookable through dbw
  // except for read-only availability customers who won't be booking
  if (!isAvailabilityReadOnly) {
    params.filter.push(V9_DIRECT_BOOK_CAPABLE_FILTER);
  }

  // transform filter into format needed for search-sdk
  params.filter = transformFilterUrlParamsForSearchSdk(
    params.filter,
    params._filter,
    params['-filter']
  );

  // don't want to track this internal call
  params.exclude_from_analytics = true;

  const searchContextResponse = await searchClient.getProviders(params);
  const searchContext = getSearchContext(searchContextResponse.data);

  // these ids will be sent to search service as part of a subsequent request to fetch appointment purposes for the global avail-tiles modal
  // search team has us limit the list to a length of 1000 due to search service/elastic search limitation
  // this logic is carried over from the flask app
  const preTruncatedLength = searchContext.terms.length || 0;
  if (preTruncatedLength > 1000) {
    searchContext.terms = searchContext.terms.slice(0, 1000);
    if (req) {
      // ssr
      await sendToKloggyr(req, {
        message: `PMC truncated the provider.id facet on search response. Original length: ${preTruncatedLength}`,
        eventName: 'search_response_truncated_provider.id_facet'
      });
    }
  }

  return searchContext;
}

export function receiveProvidersV9(
  responseData,
  responseContext,
  searchContext,
  nextLocation
) {
  const {
    _metadata: { total_providers: totalProviders = 0 } = {},
    messages: {
      search_alerts: searchAlerts = [],
      distance_expansion: searchV9DistanceExpansion = null,
      nlp_actions: nlpActions
    } = {},
    facets = [],
    suggestions = {},
    _result: providers = []
  } = responseData;

  const { config, perPage } = responseContext;
  let { searchParams } = responseContext;

  // if there are nlp actions in the search result, update searchParams to include those nlp params
  if (nlpActions) {
    searchParams = mergeSearchParamsAndNlpResult(searchParams, nlpActions);
  }

  const searchSummary = {
    ...getSearchSummary(searchParams, searchV9DistanceExpansion),
    query_string: responseContext.isSpecialtyLocationSSR
      ? ''
      : `?${querystring.stringify(searchParams)}`
  };

  const pathnameParts = nextLocation.pathname.split('/');
  const isSpecialtyLocationPage = // `/specialty/:specialtySlug/near/:locationSlug`
    pathnameParts[1] === 'specialty' && pathnameParts[3] === 'near';
  const hasLocationFacetConfigured = Boolean(config.location_facet);
  const shouldRenderLocationFacet =
    isSpecialtyLocationPage || hasLocationFacetConfigured;

  const filteredFacets = [...FACET_DISPLAY_BLACKLIST];
  const configuredFacets = new FacetConfigurator(
    config,
    filteredFacets,
    shouldRenderLocationFacet
  ).getConfiguredFacets(facets, searchParams);

  const result = {
    type: RECEIVE_SEARCH_V9,
    payload: {
      providers,
      totalProviders,
      facets: configuredFacets,
      totalPages: Math.ceil(totalProviders / perPage),
      suggestions: getSuggestionsForDisplay(suggestions),
      alerts: searchAlerts,
      query: searchParams,
      // see `get_top_specialties` in search_service.py
      // this will require a client-side API call, similar to fetching `searchContext`
      // there is a ticket for search to implement this functionality for us: https://kyruus.jira.com/browse/KENG-34063
      // in the meantime, don't display top specialties on no results pages
      topSpecialties: [],
      searchSummary,
      sortOptions: getSortOptions(config, searchParams),
      searchContext: searchContext.terms.map(({ value }) => value)
    }
  };
  return result;
}

export function fetchProviderSlotCounts(nextLocation, { req } = {}) {
  return async function (dispatch, getState) {
    try {
      const state = getState();
      const { schedulingVisibility } = visibilitySelector(state);
      if (state.config.display_availability_in_search) {
        dispatch(loadingSlotCountsStart());

        const { relationshipParam, purposeParam } =
          getRelationshipAndPurposeParams(nextLocation);

        const {
          // fetch availability only for providers on the current page, which is what `providerIds` contains
          searchV9: { providerIds }
        } = state;

        if (!providerIds.length) {
          dispatch(loadingSlotCountsEnd());
          return;
        }

        let { providers } = state;

        // providers will be mutated below
        // hence, making a copy first
        providers = { ...providers };

        const urlPath = createSlotCountQueryUrl(
          providerIds,
          relationshipParam,
          purposeParam,
          state.customerCode,
          schedulingVisibility
        );

        let url;
        if (req) {
          const appSettings = await req.getAppSettings();
          url = `${appSettings.SEARCH_V9_URL}/${urlPath}`;
        } else {
          url = `/api/searchservice-v9/${urlPath}`;
        }

        const slotResponse = await axios.get(url, {
          headers: {
            'x-consumer-groups': state.customerCode,
            'x-consumer-username': productNameSelector(state)
          }
        });

        if (slotResponse.status !== 200) {
          throw new Error();
        }

        const slotData = slotResponse.data;

        if (slotData.facets[0] && slotData.facets[0].terms) {
          providers = Object.values(providers);

          // if provider.locations location has slots, set location.slots to true
          providerSlotCountZip(providers, slotData.facets[0].terms);

          dispatch(updateProvidersAfterAppendingSlotCounts(providers));
        }

        dispatch(loadingSlotCountsEnd());

        if (providerIds.length && relationshipParam && purposeParam) {
          dispatch(
            fetchSlots(
              {
                relationship: splitOnce(relationshipParam, ':')[1],
                purpose: splitOnce(purposeParam, ':')[1]
              },
              providerIds
            )
          );
        }
      }
    } catch (e) {
      dispatch(loadingSlotCountsEnd());
      // notify availability reducer of error so as not to render <AvailabilityControls />
      dispatch(errorSlotCounts());
    }
  };
}

export function fetchProvidersV9(nextLocation, { req } = {}) {
  return async (dispatch, getState) => {
    dispatch(requestProviders());

    const isServer = Boolean(req);
    const state = getState();
    const appSettings = settingsSelector(state);
    const serverSearchServiceUrl = appSettings.SEARCH_V9_URL;
    const clientSearchServiceUrl = '/api/searchservice-v9';
    const searchServiceUrl = isServer
      ? serverSearchServiceUrl
      : clientSearchServiceUrl;
    const searchClient = new Search({
      customerCode: state.customerCode,
      productName: productNameSelector(state),
      baseURL: searchServiceUrl
    });
    const v9QueryParams = await constructV9QueryParams(nextLocation, state);
    const paramsForSearchSdk = constructParamsForSearchSdk(
      state,
      v9QueryParams,
      req
    );
    const responseContext = constructResponseContext(state, v9QueryParams, req);

    try {
      const [searchResponse, searchContext] = await Promise.all([
        searchClient.getProviders(paramsForSearchSdk),
        fetchSearchContextV9(v9QueryParams, searchClient, req, state)
      ]);
      const searchData = searchResponse.data;

      // @todo: this doesn't belong in the action, as the action is
      // fired both on the client and server
      if (!isServer && typeof window !== 'undefined') {
        window.scrollTo(0, 0);
      }

      dispatch(
        receiveProvidersV9(
          searchData,
          responseContext,
          searchContext,
          nextLocation
        )
      );
    } catch (error) {
      if (isServer) {
        const log = req.getLog();

        // omit query params as a guard against potential exposure of pii
        log.error(
          `SearchService Call [${searchServiceUrl}] failed: ${error.message}`
        );
      }

      dispatch(receiveProvidersError(error));
    }
  };
}

export function getUpdatedSearch(
  config,
  currentQuery,
  currentLocation,
  modifications
) {
  modifications.unshift({ action: 'delete_key', key: 'page' });

  // TODO KP-12123 use kqmodjs for parsing query modifications
  let query = modifications.reduce((result, mod) => {
    if (mod.key == 'filter') {
      return updateFilterModification(result, mod, config); // this function handles composite filters
    } else if (mod.action == 'append') {
      return _set(result, mod.key, mod.value);
    } else if (mod.action == 'delete_key') {
      return _omit(result, mod.key);
    }
    return result;
  }, Object.assign({}, currentQuery));

  // Reset sort when distance or availability is absent
  if (shouldResetSort(config, query)) {
    query['sort'] = config.search_widget.sort_order;
  }

  if (currentLocation) {
    query = {
      ...query,
      ...getStickyParamsFromLocation(currentLocation)
    };
  }

  return {
    pathname: currentLocation.pathname,
    search: querystring.stringify(query)
  };
}

export function toggleMobileFacets() {
  return {
    type: TOGGLE_MOBILE_FACETS
  };
}
